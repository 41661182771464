$main-primary: #1F2937;
$main-secondary: white;
$alternative-primary: #111827;
$alternative-secondary: #91949B;

@font-face {
	font-family: "Futura";
	src: local("Futura"), 
    url("./assets/fonts/Futura/Futura.ttf") format("truetype"),
    url("./assets/fonts/Futura/Futura.woff2") format("woff2");
}

@font-face {
	font-family: "Futura-Light";
	src: local("Futura-Light"), 
    url("./assets/fonts/Futura/Futura-Light.ttf") format("truetype"),
    url("./assets/fonts/Futura/Futura-Light.woff2") format("woff2");
}

$font-futura: 'Futura';
$font-futura-light: 'Futura-Light';