@import '../../../variables.scss';

.experienceItem {
    position: relative;
    display: flex;
    flex-flow: column-reverse nowrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    .textBlock {
        display: flex;
        align-items: center;
        flex-flow: column nowrap;
        justify-content: center;
        max-width: calc(100% - 2rem);
        gap: 1rem;

        .tb_title {
            display: flex;
        }

        .tb_date {
            display: flex;
            flex-flow: row nowrap;
            gap: 1rem;
        }

        .tb_description {
            display: flex;
        }

        .tb_tags {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            gap: 1rem;

            .tb_tag {
                display: flex;
                padding: 1rem;
                background-color: $main-primary;
                border-radius: 4rem;
            }
        }
    }

    .imgBlock {
        display: flex;
        max-width: calc(100% - 2rem);
    }

}

@media screen and (min-width: 1024px) {
    .experienceItem {
        position: relative;
        display: flex;
        flex-flow: row nowrap !important;
        align-items: center;
        justify-content: space-between !important;
        gap: 2rem;
    
        .textBlock {
            display: flex;
            align-items: center;
            flex-flow: column nowrap;
            justify-content: center;
            max-width: 600px !important;
    
            .tb_title {
                display: flex;
                align-self: flex-start;
            }

            .tb_date {
                display: flex;
                align-self: flex-start;
            }
    
            .tb_description {
                display: flex;
            }
        }
    
        .imgBlock {
            display: flex;
            max-width: 500px;
        }
    }

    .reverse {
        flex-flow: row-reverse nowrap !important;
    }
}

@media screen and (min-width:calc(600px + 4rem)) {
    .experienceItem {
        position: relative;
        display: flex;
        flex-flow: column-reverse nowrap;
        align-items: center;
        justify-content: center;
        gap: 2rem;

        .textBlock {
            display: flex;
            align-items: center;
            flex-flow: column nowrap;
            justify-content: center;
            max-width: 600px !important;

            .tb_title {
                display: flex;
            }

            .tb_description {
                display: flex;
            }
        }

        .imgBlock {
            display: flex;
            max-width: 600px;
        }
    }
}