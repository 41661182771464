@import '../../variables.scss';



.selector {
    &__control {
        background-color: $main-primary !important;
        border: 1px solid $main-secondary !important;
        box-shadow: 'none' !important;
        &--is-focused {
            border: 2px solid $main-secondary !important;
            box-shadow: none !important;
        }
    }

    &__indicator {
        color: $main-secondary !important;
        &:hover {
            color: $alternative-secondary !important;
        }
    }

    &__single-value {
        color: $main-secondary !important;
    }

    &__menu {
        background-color: $main-primary !important;  
    }
  
    &__option {
      &--is-focused {
        background-color: $alternative-secondary !important;
      }

      &--is-selected {
        background-color: $alternative-primary !important;
      }
    }
  }