@import './variables.scss';

body {
	margin: 0;
	font-family: $font-futura-light, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1 {
	margin: 0;
	font-family: $font-futura, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
	background: $main-primary;
	width: 0.5rem;
}
  
::-webkit-scrollbar-track {
	background: $main-secondary;
	box-shadow: inset 0 0 0.125rem $alternative-secondary;
	border-radius: 0.25rem;
}
  
::-webkit-scrollbar-thumb {
	background: $main-primary;
	border-radius: 0.25rem;
}

a:link {
	color: $main-secondary;
	background-color: transparent;
	text-decoration: none;
 }

 a:visited {
	color: $alternative-secondary;
	background-color: transparent;
	text-decoration: none;
 }

 a:active {
	color: $alternative-secondary;
	background-color: transparent;
 }