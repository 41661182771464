.experience {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    gap: 16rem;
    align-items: stretch;
    justify-content: center;
    padding: 4rem;
    font-size: large;
}

.e-title {
    display: flex;
    align-self: center;
}

@media screen and (max-width: 1024px) {
    .experience {
        padding: 2rem !important;
        gap: 8rem !important;
        font-size: medium !important;
    }
}

@media screen and (max-width: calc(600px - 4rem)) {
    .experience {
        padding: 1rem !important;
        gap: 4rem !important;
        font-size: medium !important;
    }
}