@import './variables.scss';

.app {
    position: relative;
    min-height: calc(100vh - 5rem);
    background-color: $alternative-primary;
	color: $main-secondary;
    display: flex;
    flex-flow: column nowrap;
}

.app_header {
	position: fixed;
    background-color: $main-primary;
	width: calc(100% - 2rem);
    display: flex;
    flex-flow: row nowrap;
	color: $main-secondary;
	align-items: center;
	justify-content: space-between;
	height: 2rem;
	padding: 1rem;
	top: 0;
	z-index: 99;

	.company {
		position: relative;
		display: flex;
	}

	.selector {
		position: relative;
		display: flex;
	}
}

.app_footer {
	position: relative;
    background-color: $main-primary;
    display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
    color: $main-secondary;
	height: 1rem;
	font-size: x-small;
	padding-right: 4rem;

	.copyright {
		display: flex;
	}
}