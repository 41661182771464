
@import '../../index.scss';

@keyframes scrollDown {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        transform: translateY(5px);
        opacity: 1;
    }
    100% {
        transform: translateY(10px);
        opacity: 0;
    }
}


.presentation {
    display: flex;
    padding-top: 4rem;
    flex-flow: row nowrap;
    height: calc(100vh - 4rem);
    align-items: center;
    justify-content: center;
    gap: 4rem;
    font-size: 1.2rem;
        
    .p_text {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 1rem;
        max-width: 600px;

        .p_title {
            display: flex;
            text-align: start;
        }
    
        .p_description {
            display: flex;
            text-align: start;
        }
            
        .p_links {
            display: flex;
            gap: 1rem;
            flex-flow: row nowrap;
        }
    }

    .p_picture {
        display: flex;
        height: 256px;
        width: 256px;
        border-radius: 128px;
        background-color: antiquewhite;
    }
}

.p_scrollDown {
    position: fixed;
    top: 94%;
    right: 1rem;
    animation: 3s linear infinite scrollDown;
}

@media screen and (max-width: 1024px) {
    .presentation {
        padding-top: 4rem;
        display: flex;
        flex-flow: column-reverse nowrap;
        height: calc(100vh - 4rem);
        align-items: center;
        justify-content: center;
        gap: 2rem;
        font-size: medium !important;
            
        .p_text {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            gap: 1rem;
            max-width: 600px;

            .p_title {
                display: flex;
                text-align: center;
            }

            .p_description {
                display: flex;
                text-align: center;
            }

            .p_links {
                display: flex;
                gap: 1rem;
                flex-flow: row nowrap;
            }
        }

        .p_picture {
            display: flex;
            height: 256px;
            width: 256px;
            border-radius: 128px;
            background-color: antiquewhite;
        }
    }

    .p_scrollDown {
        position: fixed;
        top: 92%;
        right: 0.25rem;
    }
}

@media screen and (max-width: 660px) {
    .presentation {
        display: flex;
        padding-top: 4rem;
        flex-flow: column-reverse nowrap;
        height: calc(100vh - 4rem);
        align-items: center;
        justify-content: center;
        gap: 1rem;
        font-size: medium !important;

        .p_text {
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            gap: 0.5rem;
            max-width: calc(100% - 4rem);

            .p_title {
                display: flex;
                text-align: center;
            }

            .p_description {
                position: relative;
                display: flex;
                text-align: center;
            }

            .p_links {
                display: flex;
                gap: 1rem;
                flex-flow: row nowrap;
            }
        }

        .p_picture {
            display: flex;
            height: 128px;
            width: 128px;
            border-radius: 128px;
            background-color: antiquewhite;
        }
    }

    .p_scrollDown {
        position: fixed;
        top: 93%;
        right: 0.25rem;
    }
}